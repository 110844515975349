body{
    background-color: #EEE;
    font-family: "Optima";
}

.link-isday {
    color: #4ECCA3;
}

.content-container{
    /* padding-bottom: 10px; */
    /* margin-top: 80px; */
    /* border-radius: 5px; */
    padding: 1.5rem 2rem;
}

.stock-images img{
    width: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.form-group label{
    font-size: 12px;
    color: #808080;
    font-style: italic;
}

a{
    text-decoration: none;
    cursor: pointer;
}

.icon-action{
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
}

.icon-action-small{
    width: 13px;
    height: 13px;
    margin-bottom: 4px;
    margin-left: 3px;
}

.icon-action-check-large{
    width: 100px;
    height: 100px;
    margin-bottom: 4px;
    margin-left: 3px;
}

.dropdown-menu {
    right: 0;
    left: auto !important;
}

.form-group:has( input[required] ) label::after, .form-group:has( select[required] ) label::after {
    content: " *" !important;
    color: red !important;
}

.form-control {
    height: 48px;
}

.form-group .p-dropdown-label {
    margin-top: -0.5rem;
}

.border-isday {
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
}

.p-column-filter-clear-button {
    display: none !important;
}

/* input - Start */

input[type="checkbox"] {
    margin-right: 5px;
}

/* input - End */


/* Dashboard - Start */

.countdown-timer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.countdown-section {
    text-align: center;
    margin: 0 20px;
}

.circle {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
}

.countdown-digit {
    z-index: 1; /* Place the digit above the background */
}

.countdown-label {
    margin-top: 5px;
    color: #000;
}

.circle-container {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Dashboard - End */

/* .p-component, .p-inputtext {
    font-size: 0.75rem;
} */

/* .p-inputtext {
    padding: 0.75rem 0 0.75rem 0.75rem;
} */

/* .p-dropdown .p-dropdown-trigger {
    width: 2rem;
} */