@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  font-family: var(--font-family);
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #555;
}

.flex-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.flex-col-scroll {
  flex-grow: 1;
  overflow: auto;
  min-height: 100%;
}

hr {
  margin: 0 !important;
  border-top: 1px solid #aaa !important;
}

.hrv {
  border-right: 1px solid #aaa !important;
}

.container-stage{
  height: 100%;
}

.p-dialog-mask{
  z-index: 1000 !important;
}